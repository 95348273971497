<template>
  <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
    <statistics-card-line
      :icon="cardIcon"
      :cardHeader="cardHeader"
      icon-right
      :statistic="cardValueParsed"
      :statisticTitle="cardTitle"
      :helpCardText="modalText"
      :statisticDiff="statsDiff"
      type="area"
      color="primary"
    />
  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

export default {
  props: {
    cardTitle: String,
    cardIcon: String,
    cardValue: Number,
    modalText: String,
    statsDiff: Number,
    cardHeader: String
  },
  components: {
    StatisticsCardLine,
  },
  computed: {
    cardValueParsed() {
      if (this.cardValue) return this.cardValue;
      return "-";
    },
  },
  data() {
    return {
      icon: "ClockIcon",
    };
  },
};
</script>
